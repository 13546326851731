import React from 'react'
import { Row, Col } from 'reactstrap'

import RelatedContentItem from './RelatedContentItem'

import './RelatedContentWidget.scss'


export default ({ 
  title = 'Related content',
  items,
  itemColProps = { xs: 12 }
 }) => 
  <div className="RelatedContentWidget mb-3">
    {title &&
      <div className="RelatedContentWidget__title">{title}</div>
    }
    {items &&
      <Row>
        {items.map((item, index) =>
          <Col {...itemColProps} key={index} className="mb-4">
            <RelatedContentItem
              {...item}
              num={index + 1}
            />
          </Col>
        )}
      </Row>
    }
  </div>
