import React from 'react'
import { graphql } from "gatsby"
import { Row, Col, Container, Button } from 'reactstrap'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Section from '../components/Section';
import BoxedCTASection from '../components/BoxedCTASection'
import RelatedContentWidget from '../components/RelatedContentWidget'

import FloatingIndex from '../components/FloatingIndex'

import './white-paper.scss'

const WhitePaperSection = (props) => {
  const { title, hash, moreLink, moreLabel } = props
  const content = props.content && props.content.childMarkdownRemark && props.content.childMarkdownRemark.html

  return (
    <Section id={hash} className="WhitePaperSection">
      <Container>
        <Row>
          <Col lg={8} className="mx-auto">
            <h3 className="text-center mb-4">{title}</h3>
            <div 
              dangerouslySetInnerHTML={{__html: content}} 
            />
            {moreLink && moreLabel &&
              <div className="text-center mt-5">
                <Button
                  color="electric-blue"
                  href={moreLink}
                >
                  {moreLabel}
                </Button>
              </div>
            }
          </Col>
        </Row>
      </Container>
    </Section>
  )
}


export default ({data}) => {
  const page = data.contentfulWhitePaper
  const { title, sections } = page
  const intro = page.intro && page.intro.childMarkdownRemark && page.intro.childMarkdownRemark.html
  const heroImageUrl = page.image && page.image.file && page.image.file.url


  const SectionList = () =>
    <ul className="WhitePaper__list">
      {sections.map(({title, hash}, index) =>
        <li key={index}>
          <a href={`#${hash}`}>{title}</a>
        </li>
      )}
    </ul>


  return (
    <Layout
      className="WhitePaperPage"
      section="white-paper"
      title={title}
    >
      <Hero
        backgroundProps={{
          style: {
            backgroundImage: heroImageUrl ? `url(${heroImageUrl})` : null
          }
        }}
      >
        <h1>{title}</h1>
        {sections &&
          <SectionList/>
        }
      </Hero>
      {/* <FloatingIndex title={title} numSections={sections.length}>
        <SectionList sections={sections} />
      </FloatingIndex> */}
      {intro &&
        <Section className="WhitePaperSection pt-md-5">
          <Container>
            <Row>
              <Col lg={{size: 8, offset: 2}}>
                <div 
                  className="white-paper-intro"
                  dangerouslySetInnerHTML={{__html: intro}}
                />
              </Col>
            </Row>
          </Container>
        </Section>
      }
      {sections && sections.map((section, index) =>
        <WhitePaperSection {...section} key={index} />
      )}
      {page.relatedContent &&
        <div className="py-3">
          <Container>
            <RelatedContentWidget
              title="Popular Content"
              items={page.relatedContent}
              itemColProps={{
                md: 4
              }}
            />
          </Container>
        </div>
      }
      <BoxedCTASection />
    </Layout>
  );
}


export const pageQuery = graphql`
  query WhitePaperBySlug($slug: String!, $localePrefix: String!) {
    contentfulWhitePaper(slug: { eq: $slug }, localePrefix: { eq: $localePrefix }) {
      id
      title
      image {
        file {
          url
        }
      }
      intro {
        childMarkdownRemark {
          html
        }
      }
      sections {
        title
        hash
        content {
          childMarkdownRemark {
            html
          }
        }
        moreLabel
        moreLink
      }
      relatedContent {
        __typename
        ...ContentfulBlogPostFragment
        ...ContentfulGuideFragment
      }
    }
  }
`
