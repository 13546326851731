import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import './FloatingIndex.scss'



const Element = ({ children, title, isOpen, toggle, numSections }) => (
  <div
    className={`floating-index-wrapper ${
      isOpen ? 'floating-index--open' : 'floating-index--closed'
    } floating-index--sections-${numSections}`}
    onClick={toggle}
  >
    <div className={`floating-index`}>
      <div className="floating-index__toggle">
        <FontAwesomeIcon
          icon={isOpen ? faChevronDown : faEllipsisV}
          className="floating-index__toggle__icon"
        />
      </div>
      <div className="floating-index__header">Section Menu</div>
      <div className="floating-index__content">
        <div className="floating-index__title">{title}</div>
        {children}
      </div>
    </div>
  </div>
)

export class FloatingIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return <Element {...this.props} {...this.state} toggle={this.toggle} />
  }
}

export default FloatingIndex
