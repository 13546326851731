import React from 'react'

import ModalVideo from './ModalVideo'

import './RelatedContentItem.scss'


const RelatedItem = ({
  image,
  title,
  subtitle,
  link,
  num,
  onClick = null
}) =>
  <div className="RelatedContentItem" onClick={onClick}>
    <div className="RelatedContentItem__thumbnail">
      {image &&
        <img
          src={image}
          alt={title}
        />
      }
      {num &&
        <span className="RelatedContentItem__num">{num}</span>
      }
    </div>
    <div href={link}>
      <span className="RelatedContentItem__title">{title}</span>
      <span className="RelatedContentItem__subtitle">{subtitle}</span>
    </div>
    {link &&
      <a href={link} className="RelatedContentItem__link" />
    }
  </div>



export default ({
  __typename,
  ...props
}) => {

  if (['ContentfulBlogPost', 'ContentfulGuide'].includes(__typename)) {
    const subtitle = (props.author && props.author.name)
      ? `By ${props.author.name} - ${props.created}`
      : props.created

    return (
      <RelatedItem
        title={props.title}
        image={props.image && props.image.thumbnail && props.image.thumbnail.src}
        subtitle={subtitle}
        link={props.link}
        num={props.num}
      />
    )
  }
  else if (__typename == 'ContentfulFacebookLive') {
    const subtitle = (props.author && props.author.name)
      ? `By ${props.author.name} - ${props.created}`
      : props.created

    return (
      <ModalVideo {...props.video}>
        {({ toggle }) => 
          <RelatedItem
            title={props.title}
            image={props.image && props.image.thumbnail && props.image.thumbnail.src}
            subtitle={subtitle}
            onClick={toggle}
            num={props.num}
          />
        } 
      </ModalVideo>
    )
  }
  else if (__typename == 'ContentfulCustomerStory') {

    return (
      <ModalVideo {...props.video}>
        {({ toggle }) => 
          <RelatedItem
            title={props.title}
            image={props.image && props.image.thumbnail && props.image.thumbnail.src}
            onClick={toggle}
            num={props.num}
          />
        } 
      </ModalVideo>
    )
  }

  return null
}

